import React, {  createContext  } from 'react';
import axios from 'axios';
import trans from "./data/translations.js";
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken,isSupported } from "firebase/messaging";
import Refill from './components/Refill.js';
const crc32 = require('crc-32');

const urlParams = new URLSearchParams(window.location.search);
const platformId = urlParams.get('platformId');
window.platformId = platformId ?? "browser"; 

//console.log("globa!!!!!!!!!!!!!!!!!1l");
if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "";//"http://localhost:3001";
}


axios.defaults.headers.common["Authorization"] = `${Cookies.get('token')}`;

//серверное время хранится в window.dateServer
window.dateServer = new Date();
if (window.dateServerInterval) clearInterval(window.dateServerInterval)
window.dateServerInterval = setInterval(()=>{
  window.dateServer.setSeconds(window.dateServer.getSeconds()+1)
},1000);
axios.interceptors.response.use(function (response) {
  // console.log(response)
  if (response?.data?.dateServer) window.dateServer = new Date(response.data.dateServer)
  return response;
}, function (error) {
  return Promise.reject(error);
});


const Context = createContext();

function T({ children }) {
  let lang = "ru";
  let x = trans[children];
  let t = null;
  if (x && x[lang]) t = x[lang];
  return <>{t || children}</>
}
function t(txt) {
  let lang = "ru";
  let x = trans[txt];
  let t = null;
  if (x && x[lang]) t = x[lang];
  return t || txt
}

const checkToken = async () => {
  try {
    const response = await axios.post('/api/loginToken', { token: Cookies.get('token') });
    if (response.data.result === "ok") {
      return [response.data.data, response.data.mode];
    } else {
      return ["error", response.data.mode];
    }

  } catch (error) {
    console.log(error.response.data);
  }
}

const logout = function () {
  Cookies.set("token","");
  document.location.reload()
}

const Alert = function (msg, description, type='error', alertData={}) {
  return new Promise((resolve,reject) => {
    window.glob("alert",{open:true, msg:msg, description:description, type:type, alertData,  onOK:resolve, onCancel:reject});
  })
}

const globalMethods = {
  showAlertRefill: (data) => Alert(data.message, <Refill isAlert={true} aiBtn={false} createRequestBtn={false} sx={{flexDirection: "column"}}/>)
}

const getConvertDate = (date) => {
  date = dayjs(date);
  const [second, millisecond] = [date.second(), date.millisecond()];
  const format = (second && millisecond) ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD" ;
  
  return (
    <>{dayjs(date).format(format)}</>
  );
}

function getImageUrl(data, field="image", size="big") {
  if (!data) return null
  const subpath = String(data._id).replace(/.{22}/,"")
  const dir = "/images/"+data.clinicid+"/"+subpath
  let path = dir+"/"+String(data._id)+"-"+field+"-"+size+".jpg"
  if (field==='pdf') {
    path = dir+"/"+String(data._id)+".pdf"
  }
  if (field==='pdfpng') {
    path = dir+"/"+String(data._id)+".png"
  }
  return axios.defaults.baseURL+path
}

function naming(data, table) {
  // console.log(data, table);
  if (!data) return "";
  if (table==="pacients") {
    let fullname = [];
    if (data.surname) fullname.push(data.surname);
    if (data.name) fullname.push(data.name);
    if (data.patronymic) fullname.push(data.patronymic);
    return fullname.join(" ");
  } 
  if (table==="clinics") return data.name
  if (table==="requests") return dayjs(data.date).format("DD.MM.YYYY HH:mm")+" "+data._pacient?.name
  if (table==="transactions") return dayjs(data.date).format("DD.MM.YYYY HH:mm")+" "+data.amount_rub+ " "+data.status
  if (table==="ais") return dayjs(data.date).format("DD.MM.YYYY HH:mm")+" "+data.id
  if (table==="accountantexpertpayments") return data?._expert.name;
  if (table==="users/experts") return data?.name;
  /* if (table==="requests") return getConvertDate(data.date).props.children+" "+data._pacient?.name
  if (table==="transactions") return getConvertDate(data.date).props.children+" "+data.amount_rub+ " "+data.status
  if (table==="ais") return getConvertDate(data.date).props.children+" "+data.id */
  if (table==="dermatoscopes") return data.name
  if (table==="users") return data.name
  if (table==="experts") return data.name
  
  return data._id;
}

function formatNumber(number) {
  if (number) return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return 0;
}

/*
const handlers = {
  save:async (table,data, setData, props, navigate)=>{
    let resp = null;
    if (data.id === 'create') resp = await axios.post("/api/"+table, data);
    else resp = await axios.put("/api/"+table+"/"+data._id, data);

    if (resp.data.result==="ok") {
      setData({})
      navigate ("/"+table)
    }
    else if (resp.data.result==="okerror") {
      setData({})
      Alert(resp.data.message)
      navigate ("/"+table)
    } else {
      Alert(resp.data.message)
    }
  },
  cancel:async(table,data, setData, props, navigate)=>{
    setData({})
    if (props.template==="popup") props.openState[1](false);
    else navigate("/"+table)
  },
  delete:async(table,data, setData, props, navigate)=>{
    let resp = null;
    resp = await axios.delete("/api/"+table+"/" + data._id);
    setData({})

    if (resp.data.result === "ok") {
      if (props.onDelete) props.onDelete();
      if (props.template==="popup") props.openState[1](false);
      else navigate("/"+table)
    } else {
      Alert(resp.data.message)
    }
  }
}
*/


const firebaseConfig = {
  apiKey: "AIzaSyBck0bUja4F_5bD2-WRtRP63tX0J2kIJUs",
  authDomain: "melanomenet-ru.firebaseapp.com",
  projectId: "melanomenet-ru",
  storageBucket: "melanomenet-ru.appspot.com",
  messagingSenderId: "389003704316",
  appId: "1:389003704316:web:7981f9edb37afaf6046479"
};




const app = initializeApp(firebaseConfig);
let messaging = null;
isSupported().then((supp)=>{
  if (supp) messaging = getMessaging(app)
}) 

//const messaging = getMessaging(app);
const pushSubscribe = async () => {
  const permission = await Notification.requestPermission()
  if (permission==="denied") {
    Alert(<T>enable permissions in browser instruction</T>)
    return
  }

  getToken(messaging, {vapidKey: "BPMhLWlJ64ATqG0ZyyxrLhpptZdoVY7_LKK_4YozYopmGiG6V3-LoluHSUtZ2amE6j8Xt9wIGLjWxr1nWpAZoqs"}).then((currentToken) => {
    if (currentToken) {
      let user = window.glob("user")
      user.firebaseToken = currentToken
      axios.put("/api/users/"+user._id,{firebaseToken:currentToken}).then(()=>{
        Alert(t("push subscribed"))
        Cookies.set("firebaseToken",currentToken);
      })
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}
//onMessage(messaging, (payload) => {
//  console.log('Message received. ', payload);
//});

//получаем меседж из сервисвокера о том, что надо перейти на новый урл
if (window.platformId !== "ios") {
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data?.action === 'redirect-from-notificationclick') window.navigate(event.data.url)
  })
}

const getFirstEnddateDermatoscope = async () => {
  const resp = await  axios.get(`/api/dermatoscopes`);

  if (resp.data.data) {
    const apparats = resp.data.data.filter((device) => new Date(device.enddate).getTime() >= new Date().getTime());
    const sortApparats = apparats.sort((a, b) => new Date(a.enddate).getTime() - new Date(b.enddate).getTime());
    if (sortApparats[0]?.enddate) return sortApparats[0].enddate;
  }
  
  return null;
}

function isValidObjectId(id) {
  //console.log(id)
  if ((typeof id === 'string' || id instanceof String ) && id.match(/^[0-9a-fA-F]{24}$/)) {
    return true;
  }
  return false;
}

function getHashNumericValue(str) {
  return crc32.str(str) >>> 0;
}

const numWord = (count, singular, pluralFew, pluralMany) => {
  if (count % 10 === 1 && count % 100 !== 11) {
    return `${count} ${singular}`;
  } else if ((count % 10 >= 2 && count % 10 <= 4) && (count % 100 < 12 || count % 100 > 14)) {
    return `${count} ${pluralFew}`;
  } else {
    return `${count} ${pluralMany}`;
  }
};


const technicalСonnectionPalette = {
  isactive: "green",
  connecting: "#f2780c",
  timeisup: "red",
}

export {
  axios,
  Context,
  globalMethods,
  technicalСonnectionPalette,
  T,t,
  checkToken,
  logout,
  Alert,
  getConvertDate,
  getImageUrl,
  naming,
  pushSubscribe,
  formatNumber,
  isValidObjectId,
  getFirstEnddateDermatoscope,
  getHashNumericValue,
  numWord
  //handlers
};