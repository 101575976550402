import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"

import {  Box } from '@mui/material';

import { T, t,  getConvertDate } from "../Globals.js"
import Heading from '../components/Heading';

const columns = [
  { field: 'date', headerName: t("date"), width: 120, renderCell: (params) => {
    if (params.formattedValue) return getConvertDate(params.formattedValue);
  } },
 
  { field: 'name', headerName: t("clinic name"), flex: 1 },
//  { field: '_request', headerName: t("pacient"), width: 130, valueGetter:(p)=>p.row._request?._pacient?.name||'' },
  //{ field: 'requestid', headerName: t("request"), width: 130 },
  //{ field: 'expertid', headerName: t("expert"), width: 130 },
];  


export function ClinicsList() {


  useEffect(() => {
    window.glob("title", <T>clinics list</T>)
  }, [])


  return (
    <Box>
      <Heading heading={<T>clinics list</T>}></Heading>
      <MyDataGrid
        searchCols={[{ col: 'name'}]}
        columns={columns}
        table="clinics"
      />
    </Box>
  );
}
