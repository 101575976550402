import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"

import { Fab, Box } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';

import { T, t, formatNumber } from "../Globals.js"
import { useNavigate } from 'react-router-dom';
import GExpense from '../components/graphics/GExpense.js';
import Heading from '../components/Heading';
import dayjs from 'dayjs';

const columns = [
  { field: 'date', type: 'dateTime', headerName: t("date"), width: 130, renderCell: (params) => {
    if (params.row.date) return dayjs(params.row.date).format("DD.MM.YYYY HH:mm");
  },
  valueGetter: (params) => {
    return params.value ? new Date(params.value) : null;
  }, 
  },
  { field: 'amount_so', headerName: t("amount so"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";
      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}>{value}</span>
    };
  } },  
  { field: 'amount_ai', headerName: t("amount ai"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";
      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}><T>{value}</T></span>
    };
  } },
  { field: 'amount_rub', headerName: t("amount rub"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";
      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}>{formatNumber(value||0)}</span>
    };
  } },

  { field: 'type', headerName: t("transaction type"), flex:1, minWidth: 190, valueGetter:s=>t("transaction type "+s.row.type) },
  { field: 'status', headerName: t("transaction status"), flex: 0.6, minWidth: 190, valueGetter:s=>t("transaction status "+s.row.status) },
  //{ field: '', headerName: t("details"), flex: 1, valueGetter:(p)=>naming(p.row,"transactions") },
  //{ field: 'requestid', headerName: t("request"), width: 130 },
  //{ field: 'expertid', headerName: t("expert"), width: 130 },
];  



export function TransactionsList() {
  const navigate = useNavigate();


  useEffect(() => {
    window.glob("title", <T>transactions</T>)
  }, [])

  const makePayment = () => {
    navigate('/transactions/create');
  }

  return (
    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:90, display: {xs: "flex", sm: "none"}}} onClick={makePayment} variant="extended" color="primary"><PaymentIcon /><T>make payment</T></Fab>
      <Heading heading={<T>detalization</T>} subheading={<T>detalization help</T>}></Heading>
    {/*  
      <Button onClick={makePayment} color="primary" sx={{maxWidth: "fit-content", margin: "1rem auto 0 auto", display: {xs: "none", sm: "flex"}}}><T>make payment</T></Button>
  */}
      <MyDataGrid
        columns={columns}
        table="transactions"
        hideCols={['amount_ai']}
        searchCols={[{ col: 'date' }]}
      />
      
    </Box>
  );
}

export function TransactionsListStatistics() {
  return <GExpense sx={{mt: 5, px: 2}} charts={["seriesSOMinus","seriesAIMinus"]}/>
}