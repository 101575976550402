import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import { Box, Stack, Typography } from '@mui/material';
import { getImageUrl, T, getConvertDate } from '../Globals';
import StatusChip from './StatusChip';

export function CardsList({ template, cards, handleСlick }) {

  if (!cards.length) return null;

  return (
    <Box sx={{"display":"flex", "gap":2, "flexWrap":"wrap", my:4, justifyContent: "center"}}>
      {template === "pacientsRequests" && cards.map((c, k) => <PacientRequestsCardItem key={k} cardData={c} handleСlick={handleСlick} />)}
    </Box>
  )
}

function PacientRequestsCardItem({ cardData, handleСlick }) {

  const cardClick = (e) => handleСlick(e, cardData);

  return (
    <Card sx={{ minWidth: 245, maxWidth: 245 }} onClick={cardClick}>
      <CardActionArea>

        <CardMedia
          component="img"
          height="160"
          image={getImageUrl(cardData, "image", "small")}
          alt="picture"
        />

        <CardContent>
          <Typography variant="h6" component="div"><T>{"status " + cardData.status}</T></Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>{getConvertDate(cardData.date)}</Typography>

          <Stack gap={1.5} mt={2} px="2px">
            <Box sx={{ width: "100%", mx: "auto", display: "flex", flexDirection: "column", gap: 0.5 }}>
              {(cardData._responses && Object.keys(cardData._responses).length !== 0) && (
                cardData._responses?.map((r) => <StatusChip {...r} key={r._id} type="so" sx={{ width: "100%", mx: "auto", "& .MuiChip-label": { pl: 0 } }} />)
              )}
            </Box>
            <Box sx={{ width: "100%", mx: "auto", display: "flex", flexDirection: "column", gap: 0.5 }}>
              {(cardData._ais && Object.keys(cardData._ais).length !== 0) && (
                cardData._ais?.map((r) => <StatusChip {...r} key={r._id} type="ai" sx={{ width: "100%", mx: "auto", "& .MuiChip-label": { pl: 0 } }} />)
              )}
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}