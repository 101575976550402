import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Img from '../components/Img'

export function QPPatientItem() {
  const { id } = useParams()
  const [images, setImages] = useState([])
  const [selectedImageId, setSelectedImageId] = useState(null)
  const [markers, setMarkers] = useState([])
  const [microImages, setMicroImages] = useState({})
  const [bigImageId, setBigImageId] = useState()
  

  // Загружаем изображения
  useEffect(() => {
    fetch(`/api/qpbaseline/${id}`)
      .then(res => res.json())
      .then(data => setImages(data.data))
  }, [id])

  // Загружаем маркеры при выборе baseline
  useEffect(() => {
    if (selectedImageId) {
      fetch(`/api/qpmarkers/${selectedImageId}`)
        .then(res => res.json())
        .then(markerData => {
          setMarkers(markerData.data)
          markerData.data.forEach(marker => {
            // Загружаем микроизображения для каждого маркера
            fetch(`/api/qpmicrosbymarker/${selectedImageId}/${marker._id}`)
              .then(res => res.json())
              .then(microData => {
                setMicroImages(prev => ({
                  ...prev,
                  [marker._id]: microData.data
                }))
              })
          })
        })
    } else {
      setMarkers([])
      setMicroImages({})
    }
  }, [selectedImageId, id])

  return (
    <div>
      <h1>QP Patient Baselines</h1>
      <Img src={"/api/qpimage/"+bigImageId} style={{width: "600px", height:"600px"}}/>
      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        {images.map(image => (
          <div
            key={image._id}
            onClick={() => {
              setSelectedImageId(image._id)
              setBigImageId(image._id)
            }}
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              cursor: 'pointer',
              backgroundColor: selectedImageId === image._id ? '#f0f0f0' : '#fff'
            }}
          >
              <Img src={"/api/qpicon/"+image._id} />
            Baseline ID: {image._id}
          </div>
        ))}
      </div>
      {selectedImageId && (
        <div>
          <h2>Markers for Baseline {selectedImageId}</h2>
          <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            {markers.map(marker => (
              <div key={marker._id} style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px' }}>
                <h3>Marker ID: {marker._id}</h3>
                <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                  {(microImages[marker._id] || []).map(micro => (
                    <img
                      key={micro._id}
                      src={"/api/qpicon/"+micro._id}
                      alt={`Micro for marker ${marker.id}`}
                      style={{ width: '50px', height: '50px', margin: '5px', cursor:"pointer" }}
                      onClick={() => {
                        setBigImageId(micro._id)
                      }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}


