import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigation from './components/Navigation';
import Header from './components/Header';
import MobileBottomNavigation from './components/MobileBottomNavigation';
//import { useLocation } from 'react-router-dom';

//import {Context} from "./Globals.js"
///import {T} from "./Globals"
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://melanomenet.ru/">
        МЕЛАНОМЕ НЕТ
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
let theme = createTheme({
  palette: {
    primary: {
      light: '#4E4E70',
      main: '#4E4E70',
      dark: '#4E4E70',
      // dark: '#006db3',
    },
    secondary: {
      light:"#ffad50",
      main:"#f58d20",
      dark:"#b56d00",
      contrastText: "#ffffff"
    },
    purpl: {
      v1:"#686895",
      v2:"#9999B7",
      v3:"#4E4E70",
      light: "#686895",
      main: '#686897',
      dark: '#4E4E70',
      contrastText: "#ffffff"
    },
    purpllight: {
      main: '#9999B7',
      dark: '#9999B7',
      contrastText: "#ffffff"
    },
    white: {
      main:"#FEFEFE",
      v1:"#FEFEFE",
      v2:"#FAFAFA",
      v3:"#A7A7A9",
    },
    orange: {
      light:"#F58D20",
      main:"#F58D20",
      dark:"#F58D20",
    },
    grey: {
      light:"#7D7D7F",
      main:"#7D7D7F",
      dark:"#7D7D7F",
    },
  },

  typography: {
    secondary: {
      color: "pink"
    },

    fontFamily:"Fira Sans",
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },

  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },

});

theme = {
  ...theme,
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'myDefault18' },
          style: {
            fontSize: 18,
            color: theme.palette.purpl.light,
            fontWeight: 700,
            whiteSpace: 'pre-line',
            fontFamily:"Fira Sans",
          },
        },
        {
          props: { variant: 'myDefault22' },
          style: {
            fontSize: 22,
            color: theme.palette.purpl.light,
            fontWeight: 700,
            whiteSpace: 'pre-line',
            fontFamily:"Fira Sans",
          },
        },
        {
          props: { variant: 'myH1' },
          style: {
            fontSize: 36,
            color: theme.palette.purpl.light,
            fontWeight: 700,
            whiteSpace: 'pre-line',
            fontFamily:"Fira Sans",
          },
        },
        {
          props: { variant: 'myH2' },
          style: {
            fontSize: 22,
            color: theme.palette.purpl.light,
            fontWeight: 700,
            whiteSpace: 'pre-line',
            fontFamily:"Fira Sans",
          },
        },
        {
          props: { variant: 'myH3' },
          style: {
            fontSize: 22,
            color: theme.palette.purpl.light,
            fontWeight: 700,
            whiteSpace: 'pre-line',
            fontFamily:"Fira Sans",
          },
        },
        {
          props: { variant: 'myH4' },
          style: {
            fontSize: 22,
            color: theme.palette.purpl.light,
            fontWeight: 400,
            whiteSpace: 'pre-line',
            fontFamily:"Fira Sans",
          },
        },
        {
          props: { variant: 'myH5' },
          style: {
            fontSize: 16,
            color: theme.palette.purpl.light,
            fontWeight: 700,
            whiteSpace: 'pre-line',
            fontFamily:"Fira Sans",
          },
        },
        {
          props: { variant: 'myH6' },
          style: {
            fontSize: 16,
            color: theme.palette.purpl.light,
            fontWeight: 700,
            letterSpacing: "0.96px",
            textTransform: "uppercase",
            whiteSpace: 'pre-line',
            fontFamily:"Fira Sans",
          },
        },
      ],
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
        outlined: {
          '&:hover': {
            backgroundColor: 'unset',
            border: "1px solid #F58D20",
            color: "#F58D20",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
         // backgroundColor: theme.palette.common.white, таким образом индикатор сиавновится белым
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            height: "2px",
            backgroundColor: theme.palette.purpl.v1,
          },
        },
      ],
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: theme.palette.orange.main
        }
      }
    },
    MuiTextField: {
      defaultProps: {
          variant: 'standard',
      },
    },
  },
};

const drawerWidth = 256;
window.theme= theme;
window.maxWidth="1000px";
window.grayColor= "#dedfe1";

export default function Paperbase(props) {

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [openMobileNavigation, setOpenMobileNavigation] = useState(false);
  const [activeDesktopNavigation, setActiveDesktopNavigation] = useState(false);
  const [burgerActive, setBurgerActive] = useState(false);

  function handleTipeDevice() {

    //если мобильное устройство
    if (window.globalState.isMobileDevice) {
      setBurgerActive(true);

    } else {
    //если десктоп
      if (!isSmUp) {
        setBurgerActive(true);
        setActiveDesktopNavigation(true);
      } else {
        setBurgerActive(false);
        setActiveDesktopNavigation(false);
      }
    }
  }

  useEffect(() => {
    handleTipeDevice();
    // eslint-disable-next-line
  }, [window.globalState.isMobileDevice, isSmUp]);

  const handleDrawerToggle = () => {
    setOpenMobileNavigation(!openMobileNavigation);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box
          component="nav"
          className='main_navigation_panel'
          sx={{ width: { sm: window.globalState.isMobileDevice ? 0 : drawerWidth }, flexShrink: { sm: 0 }}}
        >

        {(window.globalState.isMobileDevice || activeDesktopNavigation) && (
          <NavigationComponent variant="temporary" open={openMobileNavigation} onClose={handleDrawerToggle}/>
        )}
        
        {!window.globalState.isMobileDevice && (
          <NavigationComponent sx={{ display: {  xs: 'none', sm: 'block', } }} onClose={()=>{}} />
        )}

        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems:"center" }}>
          <Header handleDrawerToggle={handleDrawerToggle} burgerActive={burgerActive}/>
          <Box component="main" sx={{flex:1,width:"100%", alignItems:"stretch", display:"flex", flexDirection:"column", gap:2}}>
            {props.children}
          </Box>
          <Box component="footer" sx={{ p: 2 }}>
            <Copyright />
          </Box>
          {window.globalState.isMobileDevice && (<MobileBottomNavigation />)}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function NavigationComponent(props) {
  return (
    <Navigation
      PaperProps={{ style: { width: drawerWidth, backgroundColor:"#262424" } }}
      {...props}
    />
  );
}