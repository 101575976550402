import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
//import { Button } from '@mui/material';
import { Box,  Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { T, t } from "../Globals.js"
import Heading from '../components/Heading';
import { useParams } from 'react-router-dom';

const columns = [

    { field: 'MarkText', headerName: t("QP MarkText"), width:100},
    { field: 'MarkPoint1X', headerName: t("QP MarkPoint1X"), width:100},
    { field: 'MarkPoint1Y', headerName: t("QP MarkPoint1Y"), width:100},
    { field: 'MarkPoint2X', headerName: t("QP MarkPoint2X"), width:100},
    { field: 'MarkPoint2Y', headerName: t("QP MarkPoint2Y"), width:100},
];  
    


export function QPMarkersList() {
  const params = useParams()

  useEffect(() => {
    window.glob("title", <T>QP markers list</T>)
  }, [])

  const create = () => {
//    window.navigate('/ffapparats/create');
  }


  return (
    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:20}} onClick={create} variant="extended" color="primary"><AddIcon /><T>create</T></Fab>
      <Heading heading={<T>QP markers list</T>} style={{marginBottom:0}}></Heading>
      <MyDataGrid
        columns={columns}
        table={"qpmarkers/"+params.id}
        linktable={"qpmicrosbymarker/"+params.id}
        maxWidth="1500px"
      />
    </Box>
  );
}