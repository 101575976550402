import React,{useEffect} from 'react';
//import { useLoaderData } from "react-router-dom";

import MyDataGrid from "../components/MyDataGrid"
//import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Fab,Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {T,t,naming} from "../Globals.js";
import { useNavigate } from 'react-router-dom';
import  StatusDot  from '../components/StatusDot';
import Heading from '../components/Heading';
import dayjs from 'dayjs';

const columns = [

  { 
    field: 'name', 
    headerName: t("table fio"), 
    flex:1,
    minWidth: 200,
    valueGetter:(params)=> {
      return naming(
        {
          surname: params.row.surname || "",
          name: params.row.name || "",
          patronymic: params.row.patronymic || ""
        },
      "pacients");
    }
  },
  { 
    field: 'birth', 
    headerName: t("birth"), 
    headerClassName: 'col-birth',
    type: "date",
    align: 'center',
    minWidth: 150,
    renderCell: (params) => {
      return params.row.birth ? dayjs(params.row.birth).format("DD.MM.YYYY") : null;
      // if (params.row.birth) return getConvertDate(params.row.birth);
    },
    valueGetter: (params) => {
      return params.value ? new Date(params.row.birth) : null;
      // dayjs(params.value).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    },
  },
  {
    field: '_responses',
    headerName: t("requests"),
    sortable: false,
    flex: 1,
    minWidth: 200,
    filterable: false,
    renderCell: (params) => {
      return getCellDots(params);
    }
  },
//  { field: 'sex', headerName: t("sex"), width: 130 },
  //{ field: 'phototype', headerName: t("phototype"), width: 130 },
];

/*
export async function PacientsListLoader(){
  const resp = await axios.get("/api/pacients")
  return resp.data.data.map((a)=>{return {...a, id:a._id, }})
}
*/

function getCellDots(params) {
  
  const MAX_DOTS = 12;

  let arrDots = [];
  
  params.row._requests?.forEach( req => {
    req._responses.forEach(res => arrDots.push(<StatusDot diagnosis={res.diagnosis} type="so"  status={res.status}/>));
    // req._ais.forEach(res => arrDots.push(<StatusDot diagnosis={res.diagnosis} type="ai" status={res.status} />));
  });

  return <Stack sx={{display: 'flex', flexDirection: 'row', gap: 1, flexWrap: "wrap"}}>
    {arrDots.map((El, index) => index < MAX_DOTS ? <span key={index} >{El}</span>: null)}
    {arrDots.length - MAX_DOTS > 0 ? <span>+{arrDots.length - MAX_DOTS}</span> : null}
  </Stack>
}

export  function PacientsList() {
  const navigate = useNavigate();
  //let data = useLoaderData();

  
  useEffect(()=>{
    window.glob("title",<T>pacients</T>)
  },[])

  const createPacient = () =>{
    navigate('/pacients/create');
  }

  return (

    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:90, display: {xs: "flex", sm: "none"}}} onClick={createPacient} variant="extended" color="primary"><AddIcon /><T>create pacient</T></Fab>
      <Heading heading={<T>pacients</T>} subheading={<T>sub_pacients</T>}></Heading>
      <Button onClick={createPacient} color="primary" sx={{maxWidth: "fit-content", margin: "1rem auto 0 auto", display: {xs: "none", sm: "flex"}}}><T>create pacient</T></Button>
      <MyDataGrid
        columns={columns}
        table="pacients"
        sortModel={{field:"name",sort:"asc"}}
        searchCols={[{ col: 'name' }, { col: 'birth' }]}
        rowHeight={75}
      />
    </Box>
  );
}