import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
//import { Button } from '@mui/material';
import { Box,  Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { T, t } from "../Globals.js"
import Heading from '../components/Heading';
import { useParams } from 'react-router-dom';
import Img from "../components/Img";

const columns = [

  { field: 'ShootingDate', headerName: t("QP ShootingDate"), width:100},
  {
    field: '',
    headerName: t("image"),
    sortable: false,
    width: 200,
    align: 'center',
    renderCell: (params) => {
      //const imageUrl = getImageUrl(params.row, 'image', 'small');
      return <Img src={"/api/qpicon/"+params.row._id} alt="Image" />;
    }
  },
];  
    


export function QPMicrosbymarkerList() {
  const params = useParams()

  useEffect(() => {
    window.glob("title", <T>QP markers list</T>)
  }, [])

  const create = () => {
//    window.navigate('/ffapparats/create');
  }


  return (
    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:20}} onClick={create} variant="extended" color="primary"><AddIcon /><T>create</T></Fab>
      <Heading heading={<T>QP markers list</T>} style={{marginBottom:0}}></Heading>
      <MyDataGrid
        columns={columns}
        table={"qpmicrosbymarker/"+params.id+"/"+params.id2}
        linktable={"qpmicrosbymarker/"+params.id}
        maxWidth="1500px"
      />
    </Box>
  );
}