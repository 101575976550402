import React,{useContext, useState, useEffect} from 'react';
import scss from "./Header.module.scss";

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Logo from './Logo';
//import PeopleIcon from '@mui/icons-material/People';
//import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
//import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { Link } from 'react-router-dom';
import {T,Context,getFirstEnddateDermatoscope} from '../Globals'

import {ReactComponent as IconAireport} from "../icons/navigate/aireport.svg"
import {ReactComponent as IconComment} from "../icons/navigate/comment.svg"
import {ReactComponent as IconDoctors} from "../icons/navigate/doctors.svg"
import {ReactComponent as IconHome} from "../icons/navigate/Home.svg"
import {ReactComponent as IconProfile} from "../icons/navigate/profile.svg"
import {ReactComponent as IconReports} from "../icons/navigate/reports.svg"
import {ReactComponent as IconRequest} from "../icons/navigate/request.svg"
import {ReactComponent as IconRubble} from "../icons/navigate/rubble.svg"
import {ReactComponent as IconSettings} from "../icons/navigate/settings.svg"
import {ReactComponent as IconStatistics} from "../icons/navigate/statistics.svg"
import {ReactComponent as IconTransactions} from "../icons/navigate/transactions.svg"
import {ReactComponent as IconDermatoscope} from "../icons/navigate/dermatoscope.svg"
import {ReactComponent as IconContacts} from "../icons/navigate/contacts.svg"
import {ReactComponent as IconUnlimitedTransactions} from "../icons/navigate/unlimitedtransactions.svg"
import {ReactComponent as IconProfilev2} from "../icons/navigate/profilev2.svg"
import { Chip, SvgIcon, Tooltip } from '@mui/material';

import {ReactComponent as IconAi} from "../icons/ai.svg"
import {ReactComponent as IconSo} from "../icons/so.svg"
import {ReactComponent as IconAlarm} from "../icons/alarm.svg"
import dayjs from 'dayjs';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
//import ContactSupportIcon from '@mui/icons-material/ContactSupport';

const categories = [
  {
    label: <T>service ai</T>,
    role: 'doctor',
    children: [
      { label: <T>qp devices</T>, link:'/qpdevices', icon: <IconAireport /> , feature: ["qp"]},
      { label: <T>picture ai</T>, link:'/airequest', icon: <IconAireport /> , feature: ["aiapi"]},
      { label: <T>hand calibration</T>, link:'/handcalibration', icon: <IconAireport /> },
    ],
  },
  {
    label: <T>menu so</T>,
    role: 'doctor',
    children: [
      { label: <T>qp patients</T>, link:'/qppatients', icon: <IconProfile /> , feature: ["qp"]},
      { label: <T>requests</T>, link:'/requests', icon: <IconRequest />, globState:"requestsUnreadCount" },
      { label: <T>pacients</T>, link:'/pacients', icon: <IconProfile /> },
    ],
  },
  {
    label: <T>menu payment</T>,
    role: 'payment',
    children: [
      { label: <T>tariffs</T>, link:'/transactions/create', icon: <IconTransactions />, feature: ["ai","so"] },
      { label: <T>tariffs AI</T>, link:'/transactions/createFF', icon: <IconUnlimitedTransactions />, feature: ["unlimitedFF"] },
      { label: <T>detalization</T>, link:'/transactions', icon: <IconRubble /> },
      { label: <T>statistics</T>, link:'/transactions/statistics', icon: <IconStatistics /> },
      //{ label: <T>payments</T>, link:'/payments', icon: <SettingsIcon /> },
      //{ label: <T>menu entity</T>, icon: <TimerIcon /> },
    ],
  },
  {
    label: <T>menu expert opinion</T>,
    role: 'expert',
    children: [
      { label: <T>requests</T>, link:'/responses', icon: <IconComment />, globfunc: "newRequestsCount" },
      { label: <T>expert payments</T>, link:'/expertpayments', icon: <IconRubble /> },
      { label: <T>statistics</T>, link:'/expertpayments/statistics', icon: <IconStatistics /> },
      { label: <T>expert act</T>, link:'/expertpayments/act', icon: <IconReports /> },
    ],
  },
/*
  {
    label: <T>menu ai reports</T>,
    role: 'ai',
    children: [
      { label: <T>ais</T>, link:'/ais', icon: <IconAireport />, globfunc: "newAisCount" },
      { label: <T>ai payments</T>, link:'/aispayments', icon: <IconRubble /> },
      { label: <T>statistics</T>, link:'/aispayments/statistics', icon: <IconStatistics /> },
    ],
  },
*/
  {
    label: <T>menu administrator</T>,
    role: 'administrator',
    children: [
      //{ label: <T>menu administrator stat</T>, icon: <SettingsIcon /> },
      { label: <T>administrator clinic</T>, link:'/clinic', icon: <IconSettings /> },
      { label: <T>administrator doctors</T>, link:'/users', icon: <IconDoctors /> },
    ],
  },
  {
    label: <T>menu accountant</T>,
    role: 'accountant',
    children: [
      //{ label: <T>menu administrator stat</T>, icon: <SettingsIcon /> },
      { label: <T>bills</T>, link:'/accountant', icon: <IconReports /> },
      { label: <T>reports</T>, link:'/accountant/reports', icon: <IconReports /> },
      { label: <T>ff bills</T>, link:'/ffbills', icon: <IconReports /> },
      { label: <T>qp bills</T>, link:'/qpbills', icon: <IconReports /> }, // new qp bills
      { label: <T>ff apparats</T>, link:'/ffapparats', icon: <IconReports /> },
      { label: <T>accountant expert payments</T>, link:'/accountantexpertpayments', icon: <IconReports /> },
      { label: <T>transactions table</T>, link:'/api/accountanttransactionstable', icon: <IconReports /> },
      { label: <T>Управление</T>, link:'/control', icon: <IconReports /> },
    ],
  },
  {
    label: <T>menu superadmin</T>,
    role: 'superadmin',
    children: [
      //{ label: <T>menu administrator stat</T>, icon: <SettingsIcon /> },
      { label: <T>clinics list</T>, link:'/clinics', icon: <IconReports /> },
      { label: <T>users list</T>, link:'/profiles', icon: <IconReports /> },
    ],
  },
];

for (let i in categories) {
  categories[i].key = "aaaa"+Math.random();
  for (let j in categories[i].children) {
    categories[i].children[j].key = "aaa"+Math.random();
  }
}

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

/*
const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};
*/
export default function Navigation(props) {
  const { ...other } = props;
  const {globalState} = useContext(Context);
  const [firstDermatoscop, setFirstDermatoscop] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    getFirstEnddateDermatoscope().then((resp) => setFirstDermatoscop(resp));
    // eslint-disable-next-line
  }, []);
  
  
  const getText = (globfunc, globState) => {
    const spanStyle = { color: "white", float: "right" };
    let count = 0;

    if (globState) count = globalState[globState];
    else if (globfunc) {
      count = window.glob(globfunc);
    }

    return count > 0 ? <span style={spanStyle}>{" +" + count}</span> : null;
  }

  return (
    <Drawer variant="permanent" {...other}  sx={{zIndex: 2000}}>
      <List disablePadding>
        <ListItem  key="sdf">
          <Box sx={{mt: 2}}>
            <Logo style={{width: "100%"}}/>
          </Box>
        </ListItem>
        {isMobile && (
          <ListItem sx={{py: '2px', px: 3, my: 1, ".MuiChip-root": {cursor: "auto"}}}>
            <Stack flexDirection="row" flexWrap={"wrap"}>
              {window?.features?.so &&
                <Tooltip title={<T>count available so</T>}><Chip color="primary" className={scss.mychip} sx={{mr:1, mb:1, "span": {color: "#FAFAFA"}}} icon={<SvgIcon><IconSo /></SvgIcon>} variant="outlined" label={<T>{globalState.balance?.so || "0"}</T>} /></Tooltip>
              }
              {window?.features?.ai &&
                <Tooltip title={<T>count available ai</T>}><Chip color="primary" className={scss.mychip} sx={{mr:1, mb:1, "span": {color: "#FAFAFA"}}} icon={<SvgIcon><IconAi /></SvgIcon>} variant="outlined" label={<T>{globalState.balance?.ai || "0"}</T>} /></Tooltip>
              }
              {firstDermatoscop && <Tooltip title={<T>end date tarif</T>}><Chip color="primary" className={scss.mychip} sx={{mb:1, mr:1, "span": {color: "#FAFAFA"}}} icon={<SvgIcon><IconAlarm /></SvgIcon>} variant="outlined" label={dayjs(firstDermatoscop).format("DD.MM.YYYY")} /></Tooltip>}
            </Stack>
          </ListItem>
        )}
        <ListItem  disablePadding key="sdf2">
          <Link style={{width: "100%"}} to='/' onClick={()=>{props.onClose()}}>
            <ListItemButton sx={item}>
              <ListItemIcon sx={{width:20, height:20, alignItems: "center"}}><IconHome /></ListItemIcon>
              <ListItemText><T>menu mainpage</T></ListItemText>
            </ListItemButton>
          </Link>
        </ListItem>
        {isMobile && (
          <ListItem  disablePadding key="sdf4">
            <Link style={{width: "100%"}} to='/profile' onClick={()=>{props.onClose()}}>
              <ListItemButton sx={item}>
                <ListItemIcon sx={{width:20, height:20, alignItems: "center"}}><IconProfilev2 /></ListItemIcon>
                <ListItemText><T>profile</T></ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
        )}
        {globalState?.user?.role_doctor &&
          <ListItem  disablePadding key="sdf5">
            <Link style={{width: "100%"}} to='/dermatoscopes' onClick={()=>{props.onClose()}}>
              <ListItemButton sx={item}>
                <ListItemIcon sx={{width:20, height:20, alignItems: "center"}}><IconDermatoscope /></ListItemIcon>
                <ListItemText><T>menu myequipment</T></ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
        }
        <ListItem  disablePadding key="sdf3">
          <Link style={{width: "100%"}} to='/contacts' onClick={()=>{props.onClose()}}>
            <ListItemButton sx={item}>
              <ListItemIcon sx={{width:20, height:20, alignItems: "center"}}><IconContacts /></ListItemIcon>
              <ListItemText><T>menu contacts</T></ListItemText>
            </ListItemButton>
          </Link>
        </ListItem>


        {categories.map(({ label, children , role, key},index) => {
          return (
            <Box key={key} sx={{ display:window.glob("user.role_"+role)?"block":"none" }} >
              <ListItem sx={{ py: 2, px: 3 }} >
                <ListItemText sx={{ color: '#fff' }}>{label}</ListItemText>
              </ListItem>
              {children.filter(({ feature }) => { 
                if (!feature) return true;
                if (feature.length > 1) return (window.features["ai"] || window.features["so"]) ? true : false;
                  else if (window.features[feature]) return true;
                return false;
               })
                .map(({ label, icon, link, globfunc, globState, key }) => (
                  <ListItem disablePadding key={key} sx={{ ".Mui-selected": { color: "rgba(255, 255, 255, 0.7) !important" } }}>
                    <Link style={{ width: "100%" }} target={(link === '/api/accountanttransactionstable') ? '_blank' : null} to={link} onClick={() => { props.onClose() }}>
                      <ListItemButton selected={document.location.pathname === link} sx={item}>
                        <ListItemIcon sx={{ width: 20, height: 20, alignItems: "center" }}>{icon}</ListItemIcon>
                        <ListItemText sx={{ textWrap: "nowrap" }}>{label}{getText(globfunc, globState)}</ListItemText>
                      </ListItemButton>
                    </Link>
                  </ListItem>
              ))}
              <Divider sx={{ mt: 2 }} />
            </Box>
          )
         
        })}
      </List>
    </Drawer>
  );
}