import React from 'react'
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'

import {ReactComponent as IconRequest} from "../icons/navigate/request.svg"
import {ReactComponent as IconProfile} from "../icons/navigate/profile.svg"
import {ReactComponent as IconTransactions} from "../icons/navigate/transactions.svg"
import { t } from '../Globals'

const navStyles = {"& .MuiBottomNavigationAction-label": {mt: 1, color: "white !important"}}

function MobileBottomNavigation() {

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={3}>
      <BottomNavigation
        showLabels
        sx={{ backgroundColor: "#262424", height: 75 }}
        value={0}
        onChange={(event) => {
          const {link} = event.target.dataset
          window.navigate(link)
        }}
      >
        <BottomNavigationAction data-link="/requests" sx={navStyles} label={t("requests")} icon={<IconRequest />} />
        <BottomNavigationAction data-link="/pacients" sx={navStyles} label={t("pacients")} icon={<IconProfile />} />
        <BottomNavigationAction data-link="/transactions/create" sx={navStyles} label={t("responses")} icon={<IconTransactions />} />
      </BottomNavigation>
    </Paper>
  )
}

export default MobileBottomNavigation