import React, { useState, useEffect} from 'react';
import { T,t,axios, Alert , naming, formatNumber, technicalСonnectionPalette} from "../Globals.js"
import { Stack, Slider, Box, Typography, Paper,Button,FormControlLabel,Checkbox,Table,TableHead,TableBody,TableCell,TableContainer,TableRow, FormHelperText, } from '@mui/material';
import {Alert as MaterialAlert} from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import LinkField from '../components/LinkField.js';
import { useLocation, useParams } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import Heading from '../components/Heading.js';
import ToPdfButton from '../components/ToPdfButton.js';

import {ReactComponent as IconBank} from "../icons/bank.svg";
import {ReactComponent as IconCreditCard} from "../icons/creditcard.svg";
import useTransaction from '../hooks/useTransactions.js';
import useValidation from '../hooks/useValidation.js';

//const aiVariants = [0,3,10,50,300,"unlimitedFF"]; 
const aiVariants = [0,3,10,50,300]; 
const soVariants = [0,3,5,10,20,50]; 
const marksSO = soVariants.map((x,i)=>{return {value:i, label:t(x)}});
const marksAI = soVariants.map((x,i)=>{return {value:i, label:t(x)}});


export function TransactionsItem(props) {
  let [data, setData] = useState({});

  const createHandler = async (data) => {
    if (data?.yookassa?.confirmation?.confirmation_url) {
      document.location.href=data.yookassa.confirmation.confirmation_url;
    }
  }

  /* Сюда передается transactionsData при нажатии на кнопку Оплатить/Сформировать счет */
  const saveHandler = async (d) => {
    if (d) Object.keys(d).forEach((k) => data[k] = d[k]);
  }
  const applyHandler = async (d) => {
    if (d) Object.keys(d).forEach((k) => data[k] = d[k]);
  }
  // const deleteHandler = async () => {}
  // const cancelHandler = async () => {}
  const content = () => {

    if (data?.id === "createFF") return <TransactionsItemFotofinder />
    if (data?.type === "spending") return <TransactionsItemSpending />
    if (data?.type === "unlimitedFF") return <TransactionsItemFotofinder /> 
    
    return (
      <TransactionsItemDeposit />
    )  
  }

  return (
    <>
      <ItemTemplate {...props}  defaults={{unlimitedFF:undefined, paymentmethod: window.globalState?.user?.role_administrator ? null : "card"}} table="transactions" data={data} setData={setData} onCreate={createHandler} onSave={saveHandler} onApply={applyHandler} buttons={["close"]}>
        {content()}
      </ItemTemplate>
    </>
  )
}


export function TransactionsItemDeposit(prop) {

  const location = useLocation();
  useEffect(() => {
    // eslint-disable-next-line
    const matches = location.pathname.match(/\/([^\/]+)$/);
    return () => prop.setData({id: matches[1]});
    // eslint-disable-next-line
  },[location.pathname]);

  useEffect(() => {
    if (!prop.data?.id) prop.setData({...prop.data, id: "create"});
    // eslint-disable-next-line
  }, []);

  const [hideTableCalcInfo, setHideInfo] = useState({ai: false, so: false});
  const {transactionData, setTransactionData, getCalcResult} = useTransaction({amount_ai: 0, amount_so: 0, ...prop.data});
  
  const handleChangeSlider = (event) => {
    setHideInfo({...hideTableCalcInfo, [event.target.name.slice(-2)]: true})
    if (event.target.name === "amount_so") setTransactionData({...transactionData, "amount_so": soVariants[event.target.value]});
    if (event.target.name === "amount_ai") setTransactionData({...transactionData, "amount_ai": aiVariants[event.target.value]});
  }

  const handleChangeCommitted = async (variant, value) => {    
    setTransactionData({...transactionData, ...(await getCalcResult(transactionData))});
    setHideInfo({...hideTableCalcInfo, [variant.slice(-2)]: false});
  };


  return (
    <Stack sx={{gap:5, flexDirection: {xs:"column", md:"row"},   justifyContent: "center", mt:2}}>
      {(prop?.data?.paymentmethod !== "gift") && (
        <Stack sx={{gap:2, flex:1}}>
          <Heading heading={<T>tariffs pay ai</T>} subheading={<T>onetime tariffs descr</T>} style={{margin: 0}}/>
          <Stack sx={{flexDirection: "column", gap: 3}}>
          {(window.features.so || prop.data?._id) && (
            <Box>
              <Stack sx={{flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="h6" component="div">«<T>balance so</T>»</Typography>
                <Typography sx={{display: "flex", alignItems: "end"}} variant="body2" component="div">{"1 запрос = "}{formatNumber(transactionData?.price_so||0)}{"р"}</Typography>
              </Stack>
              <Slider
                value={soVariants.indexOf(transactionData.amount_so) || 0}
                name="amount_so"
                onChange={handleChangeSlider}
                onChangeCommitted={(event, value) => handleChangeCommitted("amount_so", value)}
                getAriaValueText={value => value}
                step={1}
                min={0}
                max={soVariants.length-1}
                marks={marksSO}
                sx={{mt:1, ml:"10px", width:"calc(100% - 20px)"}}
                disabled={prop.data?.id === "create" ? false : true}
              />
            </Box>
          )}
          {(window.features.ai || prop.data?._id) && (
            <Box>
              <Stack sx={{flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="h6" component="div"><T>transactions create aicount</T></Typography>
                <Typography sx={{display: "flex", alignItems: "end"}} variant="body2" component="div">{"1 запрос = "}{formatNumber(transactionData?.price_ai||0)}{"р"}</Typography>
              </Stack>
              <Slider
                value={aiVariants.indexOf(transactionData.amount_ai) || 0}
                name="amount_ai"
                onChange={handleChangeSlider}
                onChangeCommitted={(event, value) => handleChangeCommitted("amount_ai", value)}
                getAriaValueText={value => value}
                step={1}
                marks={marksAI}
                min={0}
                max={aiVariants.length-1}
                sx={{mt:1, ml:"10px", width:"calc(100% - 20px)"}}
                disabled={prop.data.id !== "create" ? true : false}
              />
            </Box>
          )}
          </Stack>
        </Stack>
      )}
      
      <Paper variant="outlined" square sx={{ px:2, p:4, flex: prop?.data?.paymentmethod === "gift" ? 0.4 : 1, display: "flex", flexDirection: "column", backgroundColor: window.theme.palette.white.v2, borderStyle: "none"}}>
        <Typography component="div" variant='myDefault22' mb={1}>{prop?.data?.paymentmethod === "gift" ? <T>payment method gift</T> : <T>order</T>}</Typography>
        <TableContainer>
          <Table sx={{}}>
            <TableHead>
              <TableRow>
                <TableCell sx={{p: 1, pl: 0, width:150}}><T>denomination</T></TableCell>
                <TableCell align='right' sx={{p: 1}}><T>count</T></TableCell>
                {prop?.data?.paymentmethod !== "gift" && (<>
                  <TableCell align='right' sx={{p: 1}}><T>price</T></TableCell>
                  <TableCell align='right' sx={{p: 1}}><T>sum</T></TableCell>
                </>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {(window.features.so || prop.data?._id) &&
                <TableRow>
                  <TableCell sx={{p: 1, pl: 0}}><T>transactions create socount</T>:</TableCell>
                  <TableCell align='right' sx={{p: 1}}>{hideTableCalcInfo.so ? "" : transactionData.amount_so}</TableCell>
                  {prop?.data?.paymentmethod !== "gift" && (<>
                    <TableCell align='right' sx={{p: 1}}>{hideTableCalcInfo.so ? "" : formatNumber(transactionData.price_so || 0)}</TableCell>
                    <TableCell align='right' sx={{p: 1}}>{hideTableCalcInfo.so ? "" : formatNumber((transactionData.amount_so * transactionData.price_so))}</TableCell>
                  </>)}
                </TableRow>
              }
              {(window.features.ai || prop.data?._id) && 
                <TableRow>
                  <TableCell sx={{p: 1, pl: 0}}><T>transactions create aicount</T>:</TableCell>
                  <TableCell align='right' sx={{p: 1}}>{hideTableCalcInfo.ai ? "" : transactionData.amount_ai}</TableCell>
                  {prop?.data?.paymentmethod !== "gift" && (<>
                    <TableCell align='right' sx={{p: 1}}>{hideTableCalcInfo.ai ? "" : formatNumber(transactionData.price_ai || 0)}</TableCell>
                    <TableCell align='right' sx={{p: 1}}>{hideTableCalcInfo.ai ? "" : formatNumber((transactionData.amount_ai * transactionData.price_ai))}</TableCell>
                  </>)}
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <FinishContent prop={prop} transactionData={transactionData} setTransactionData={setTransactionData}/>
      </Paper>
    </Stack>
    
  );
}

export function TransactionsItemFotofinder(prop) {

  const [fflist, setFflist] = useState(null);
  const {transactionData, setTransactionData, getCalcResult} = useTransaction(prop.data);
  const {myerrors} = useValidation(transactionData, ["dermatoscopes"]);
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line
    const matches = location.pathname.match(/\/([^\/]+)$/);
    return () => prop.setData({id: matches[1]});
    // eslint-disable-next-line
  },[location.pathname]);

  useEffect(()=>{
    axios.get("/api/dermatoscopes").then(resp => {
      if (resp.data.result === "ok") setFflist(resp.data.data)
      else {
        Alert(resp.data.message)
      }
    })
  },[]);

  useEffect(() => {
    calculateRequest();

    async function calculateRequest() {
      await getCalcResult(transactionData);
    } 
    // eslint-disable-next-line
  },[transactionData?.dermatoscopes?.length, transactionData.unlimitedFF]);

  return (
    <Stack sx={{gap:5, flexDirection: {xs:"column", md:"row"},  width:"100%", justifyContent: "center", mt:2}}>
      <Stack sx={{gap:0, flex:0.8}}>
        <Heading heading={<T>unlimited tariffs</T>} style={{margin: 0}}></Heading>
        <Typography component="pre" sx={{whiteSpace: "pre-line"}}><T>unlimitedFF description</T></Typography>
        <Typography component="pre" sx={{whiteSpace: "pre-line", mt:3, "strong": {color: "gray"}}}><strong><T>attention</T>!</strong> <T>unlimitedFF description 2</T></Typography>
        <Typography component="pre" sx={{whiteSpace: "pre-line", mt:3}}><T>unlimitedFF description 3</T></Typography>
        <Typography component="pre" sx={{whiteSpace: "pre-line", mt:3, "strong": {color: "gray"}}}><strong><T>unlimitedFF description 4</T></strong></Typography>
      </Stack>
      
      <Paper variant="outlined" square sx={{ p:4,flex:1, display: "flex", flexDirection: "column", backgroundColor: window.theme.palette.white.v2, borderStyle: "none"}}>
        <Typography component="div" variant='myDefault22'><T>make payment ff</T></Typography>
        <Stack>
          <FormHelperText error>
            {myerrors?.includes("dermatoscopes") ? <T>required</T> : ""}
          </FormHelperText>
          {fflist && fflist.length > 0 && fflist.map((ff, index) => {
            console.log(ff?._availabilitystatus)
            
            return (
              <>
              <FormControlLabel
                key={ff._id}
                checked={transactionData?.dermatoscopes?.indexOf(ff._id) >= 0 || false}
                name="dermatoscopes"
                control={<Checkbox />}
                label={<>{naming(ff, 'dermatoscopes')}{'\u00A0\u00A0\u00A0'}{<small Style={`font-style: italic; font-weight: bold; color:${technicalСonnectionPalette[ff?._availabilitystatus]};`}><T>{ff?._availabilitystatus}</T></small>}</>}
                disabled={!!prop.data?._id}
                onChange={(event) => {
                  if (!transactionData?.dermatoscopes) transactionData.dermatoscopes = [];
                  // Если стоит галочка
                  if (event.target.checked) {
                    const index = transactionData.dermatoscopes.indexOf(ff._id);
                    if (index < 0) transactionData.dermatoscopes.push(ff._id);
                  } else {
                    // Галочка была убрана
                    const index = transactionData.dermatoscopes.indexOf(ff._id);
                    if (index >= 0) transactionData.dermatoscopes.splice(index, 1);
                  }
                  if (!transactionData.dermatoscopes.length) delete transactionData.dermatoscopes;
                  
                  if ((transactionData?.paymentmethod === "trial" || transactionData?.unlimitedFF === "trial 30") && ff?._availabilitystatus !== "isnotpaid") {
                    setTransactionData({...transactionData, paymentmethod:"card", unlimitedFF:"quartal"});
                  } else {
                    setTransactionData({...transactionData});
                  }
                }}
              />
              </>
            )
          })}

          <FFBtn fflist={fflist}/>
          <TariffPeriod prop={prop} transactionData={transactionData} setTransactionData={setTransactionData} fflist={fflist}/>
          <FinishContent prop={prop} myerrors={myerrors} transactionData={transactionData} setTransactionData={setTransactionData}/>
          
        </Stack>
      </Paper>
    </Stack>
  )
}

export function TransactionsItemSuccess(prop) {
  const params = useParams();
  let id = prop.data?._id || prop.id || params.id
  const [status, setStatus] = useState("");
  const [counter, setCounter] = useState(0);
  //console.log("111111111 "+status+" "+counter)
  useEffect(()=>{
    if (counter < 50) {
      axios.get("/api/transactions/" + id+"/checkpayment").then((resp)=>{
        if (resp.data.result === "ok") {
          if (resp.data.data.status === "completed") {
            setTimeout(()=>{window.navigate("/transactions/"+id)}, 3000)
          } else {
            setTimeout(()=>{setCounter(counter+1)}, 10000)
          }
          setStatus(resp.data.data.status)
        }
      })
    }
  },[counter,id]);
  return (<>
    {status==="completed" && <T>paid success</T>}
    {status==="pending" && <T>paid pending</T>}
    
  </>)
}

function TransactionsItemSpending(prop) {

  useEffect(() => {
    // eslint-disable-next-line
    const matches = location.pathname.match(/\/([^\/]+)$/);
    return () => prop.setData({id: matches[1]});
    // eslint-disable-next-line
  },[location.pathname]);
 
  return (
    <Stack sx={{gap:3}}>
      <TableContainer >
        <Table sx={{maxWidth:"300px"}}>
          <TableBody>
            <TableRow>
              <TableCell><T>transactions create socount</T>:</TableCell>
              <TableCell>{prop.data?.amount_so}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><T>transactions create aicount</T>:</TableCell>
              <TableCell>{prop.data?.amount_ai}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><T>amount rub</T>:</TableCell>
              <TableCell>{prop.data?.amount_rub}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box> 
        <LinkField data={prop.data} table="requests" field="_request" label={<T>request item</T>} name="requestid" readOnly={true} />
      </Box>
    </Stack>
  )
}


function FFBtn({fflist}) {
  return (
    <>
    {!fflist || fflist.length === 0 ? 
      <Button onClick={()=>{window.navigate("/dermatoscopes")}} sx={{width: "fit-content"}}><T>ff please create</T></Button>
      :
      <Button onClick={()=>{window.navigate("/dermatoscopes")}} sx={{width: "fit-content"}} size="small" variant="text"><T>ff list manage</T></Button>
    }
    </>
  )
}

function TariffPeriod({prop, transactionData, setTransactionData, fflist}) {
  
  const getButtonsProps = (value, name) => {
    return  transactionData[name] === value ? {variant: "contained", color: "secondary"} : {variant: "outlined", color: "grey"};
  }
  const getTitle = () =>  {
    if (prop?.data?._id) {
      return "selected tariff";
    }
    return transactionData?.status ? "selected tariff" : "select tariff";
  } 
  const checkSelectedDermatoscopeTrial = () => {
    if (prop?.data?.id === "createFF") {
      const paidffs = fflist?.filter(ff => ff?._availabilitystatus !== "isnotpaid").map(ff => ff._id);
      if (paidffs?.length) {
        for (let index = 0; index < paidffs.length; index++) {
          if (transactionData?.dermatoscopes?.includes(paidffs[index])) {
            return true;
          }
        }
      }
    }

    return false;
  }

  const getBtnQuartal = () => {
    return (
      <Button 
          {...getButtonsProps("quartal","unlimitedFF")} 
          sx={{width: "40%", fontWeight: 600}}
          name="unlimitedFF" 
          value="quartal" 
          onClick={()=>prop?.data?.status ? null : setTransactionData({...transactionData, unlimitedFF:"quartal", paymentmethod:  transactionData?.paymentmethod === "trial" ? null : transactionData?.paymentmethod})}
        >
          <T>90 days</T>
      </Button>
    );
  }

  const getBtnYear = () => {
    return (
      <Button
        {...getButtonsProps("year","unlimitedFF")} 
        sx={{width: "40%", fontWeight: 600}}
        name="unlimitedFF" 
        value="year" 
        onClick={()=>prop?.data?.status ? null : setTransactionData({...transactionData, unlimitedFF:"year", paymentmethod: transactionData?.paymentmethod === "trial" ? null : transactionData?.paymentmethod})}
      >
      <T>year</T>
    </Button>
    );
  }

  const getBtnTrial30 = () => {
    if (checkSelectedDermatoscopeTrial()) {
      return;
    }

    return (
      <Button
        {...getButtonsProps("trial 30","unlimitedFF")} 
        sx={{width: "40%", fontWeight: 600}}
        name="unlimitedFF" 
        value="trial 30" 
        onClick={()=>prop?.data?.status ? null : setTransactionData({...transactionData, unlimitedFF:"trial 30", paymentmethod: transactionData?.paymentmethod})}
        children={(d) => console.log(d)}
      >
      <T>payment method trial</T><br/><T>30 days</T>
    </Button>
    );
  }

  /*
  const getBtnTrial = () => {
    if (checkSelectedDermatoscopeTrial()) {
      return;
    }

    return (
      <Button
        {...getButtonsProps("trial","unlimitedFF")} 
        sx={{width: "40%", fontWeight: 600}}
        name="unlimitedFF" 
        value="trial" 
        onClick={()=>prop?.data?.status ? null : setTransactionData({...transactionData, unlimitedFF:"trial", paymentmethod:"trial"})}
        children={(d) => console.log(d)}
      >
      <T>payment method trial</T><br/><T>20 days</T>
    </Button>
    );
  }
  */

  return (
    <>
      <Typography component="div" variant='myDefault18' color={"black"} sx={{mt:2, mx: "auto"}}><T>{getTitle()}</T></Typography>
      <Stack sx={{flexDirection: "row", justifyContent: "space-around", mt: 1, gap: "1rem"}}>
        {/* {prop?.data?.status && transactionData["unlimitedFF"] === "trial" && getBtnTrial()} */}
        {prop?.data?.status && transactionData["unlimitedFF"] === "trial 30" && getBtnTrial30()}
        {prop?.data?.status && transactionData["unlimitedFF"] === "quartal" && getBtnQuartal()}
        {prop?.data?.status && transactionData["unlimitedFF"] === "year" && getBtnYear()}

        {!prop?.data?.status && (
          <>
            {/* {getBtnTrial()} */}
            {getBtnTrial30()}
            {getBtnQuartal()}
            {getBtnYear()}
          </>
        )}
      </Stack>
    </>
  );
}

function FinishContent({prop, transactionData, setTransactionData, myerrors}) {

  const getCompletedAlert = () => {
    return (
      <>
      <MaterialAlert severity="info" color='warning'>
        <Typography><T>payment successful</T></Typography>
        <Typography><T>contact yourself</T></Typography>
        <br/>
        <Typography><T>questions contacts</T>: <RouterLink to={"/contacts"}><T>contacts</T></RouterLink></Typography>
      </MaterialAlert>
    </>
    );
  };

  return (
    <>
      <Box mt={2}>
        <Itog prop={prop} transactionData={transactionData}/>
      </Box>
      
      {(!transactionData?.status && !prop.data?.status) && (
        <>
        <VariantPaymentButtons prop={prop} transactionData={transactionData} setTransactionData={setTransactionData}/>
        <VariantPaymentText prop={prop} transactionData={transactionData}/>
        {getFinishBtn([prop, transactionData, myerrors])}
        </>
      )}

      {prop.data?.type==="deposit" ? 
        <></> 
        :
        <>
        {((transactionData?.paymentmethod==="card" && transactionData?.status==="completed") || (prop?.data?.paymentmethod==="card" && prop?.data?.status==="completed")) && (
          <><br/>{getCompletedAlert()}</>
        )}
        {((transactionData?.paymentmethod==="bank" && transactionData?.status==="completed") || (prop?.data?.paymentmethod==="bank" && prop?.data?.status==="completed")) && (
          <><br/>{getCompletedAlert()}</>
        )}
        {((transactionData?.paymentmethod==="trial" && transactionData?.status==="completed") || (prop?.data?.paymentmethod==="trial" && prop?.data?.status==="completed")) && (
          <>
            <br/>
            <MaterialAlert severity="info" color='warning'><Typography><T>wait confirmation payment 2</T></Typography>
              <br/>
              <Typography><T>questions contacts</T>: <RouterLink to={"/contacts"}><T>contacts</T></RouterLink></Typography>
            </MaterialAlert>
          </>
        )}
        {((transactionData?.paymentmethod==="bank" && transactionData?.status==="pending") || (prop?.data?.paymentmethod==="bank" && prop?.data?.status==="pending")) && (
          <>
            <br/>
            <MaterialAlert severity="info" color='warning'><Typography><T>wait confirmation payment</T></Typography>
              <br/>
              <Typography><T>questions contacts</T>: <RouterLink to={"/contacts"}><T>contacts</T></RouterLink></Typography>
            </MaterialAlert>
          </>
        )}
        </>
      }
    </>
  );
}

function VariantPaymentButtons({prop, transactionData, setTransactionData}) {
  const [variantButton, setVariantButton] = useState({
    bank: prop.defaults?.paymentmethod === "bank" ? "outlined" : "text", 
    card: prop.defaults?.paymentmethod === "card" ? "outlined" : "text",
  });

  if (transactionData?.paymentmethod === "trial") {
    return;
  }

  const handleChangeVariantPay = event => {
    const variant = event.currentTarget.value;
    setTransactionData({...transactionData, [event.currentTarget.name]: variant});
    const newVariantButton = variant === "card" ? { bank: "text", card: "outlined" } : { bank: "outlined", card: "text" };
    setVariantButton(newVariantButton);
  }

  const getButtonsProps = (value, name) => {
    const { id, amount_rub, paymentmethod } = transactionData;
    
    if (id === "create" && !amount_rub && paymentmethod === "trial") return { variant: "text", disabled: true, color: "grey" };
  
    return transactionData[name] === value ? { variant: "contained", color: "orange" } : { variant: "outlined", color: "grey" };
  };

  return (
    <Stack sx={{flexDirection: "row", justifyContent: "center", gap: 2, mt: 2}}>
      <Button 
        color="grey" 
        {...getButtonsProps("card","paymentmethod")}
        variant={variantButton.card}
        sx={{width: 78, height: 78}}
        name="paymentmethod" 
        value="card" 
        onClick={handleChangeVariantPay}
      >
        <IconCreditCard/>
      </Button>
      {window.globalState?.user?.role_administrator && (
        <Button 
          color="grey" 
          {...getButtonsProps("bank","paymentmethod")}
          variant={variantButton.bank} 
          sx={{width: 78, height: 78}}
          name="paymentmethod" 
          value="bank" 
          onClick={handleChangeVariantPay}
        >
        <IconBank/>
      </Button>
      )}
      
    </Stack>
  );
}

function VariantPaymentText({prop, transactionData}) {
  return (
    <Typography sx={{mt: 2, textAlign: "center"}}><strong><T>payment method</T>:</strong> {(() => {
      if (prop.data?.id === "create" && transactionData.amount_rub === 0) return <T>not selected</T>
      else if (transactionData?.paymentmethod) return <T>{"payment method " +transactionData.paymentmethod}</T>
      else return <T>not selected</T>
    })()}</Typography>
  );
}

function Itog({prop, transactionData}) {

  return (
    <>
      {transactionData?.paymentmethod !== "gift" && (
        <Stack sx={{flexDirection: "row", justifyContent: "space-between"}}>
          <Typography component="div" variant='h6'><strong><T>itogo</T>:</strong><br/><strong>(<T>not NDC</T>)</strong></Typography>
          <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Typography component="div" variant='h6' sx={{whiteSpace: "nowrap", ml:2}}><strong>{formatNumber(transactionData?.yookassa?.amount?.value.slice(0, -3) || transactionData.amount_rub || 0)} ₽</strong></Typography>
          </Box>
        </Stack>
      )}
      {transactionData?.id !== "create" && (
        <Typography variant='subtitle2' sx={{mt: 1, fontStyle: "italic", maxWidth: "60%"}}><T>not auto renewal</T></Typography>
      )}
      
      {prop.data?._id && (
        <>
          {
            transactionData.paymentmethod==="bank" && 
            <Box sx={{display: "flex", justifyContent: "end"}}>
              <ToPdfButton sx={{my:1}} color="primary" url={"/api/transactions/"+prop.data._id+"/bill.html"}><T>download bill</T></ToPdfButton>
            </Box> 
          }
          <Typography sx={{textAlign: "right", mr:0.5, mt: 0.5}}><T>transaction method</T>: <T>{"payment method "+transactionData.paymentmethod}</T></Typography>
          <Typography sx={{textAlign: "right", mr:0.5}}><T>transaction status</T>: <T>{"transaction status "+prop.data?.status}</T></Typography>
        </>
      )}
    </>
  );
}

function getFinishBtn([prop, transactionData, myerrors]) {
  
  const sx = {mt: 1, mx: "auto", display: "block", minWidth: "40%"};  

  const getText = () => {
    switch (transactionData.paymentmethod) {
      case "bank":
        return "create an account";
      case "card":
        return "pay";
      default:
        return "use"
    }
  }

  const renderButton = (onClick) => {
    return (
      <Button onClick={() => {prop.onSave(transactionData); onClick(myerrors)} } 
        variant="contained" sx={sx}>
        <T>{getText()}</T>
      </Button>
    )
  };
  
  if (prop.data?.id === "createFF" && !!transactionData.unlimitedFF) {
    if (transactionData?.dermatoscopes?.length) {
      if (transactionData.paymentmethod === "trial")  return  renderButton(prop.apply)
      if (transactionData.paymentmethod === "bank")   return  renderButton(prop.apply)
      if (transactionData.paymentmethod === "card")   return  renderButton(prop.save)
    }
  } else if (prop.data?.id === "create" && (transactionData.paymentmethod === "bank" || transactionData.paymentmethod === "card") && (transactionData.amount_rub)) {
    return renderButton(transactionData.paymentmethod === "bank" ? prop.apply : prop.save);
  }
    
  return <Button disabled variant="contained" sx={sx}><T>{getText()}</T></Button>;
}