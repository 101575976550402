import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import {  T } from "../Globals.js"
import { Stack,TextField,/*RadioGroup, FormGroup,FormControlLabel, Radio,*/ Button } from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import Heading from '../components/Heading.js';
//import ItemPopup from "../components/ItemPopup";


export function ClinicsItem(props) {
  const params = useParams()
  let user = window.glob("user")
  //let id = params.id || user.clinicid
  let id = props.data?._id || props.id || params.id || user.clinicid

  let [data, setData] = useState({})
  
  const saveHandler = async () => {

  }

  const cancelHandler = async () => {
  }
 
  useEffect(() => {
    window.glob("title", <T>clinic</T>)
  }, [id])


    return (
      <ItemTemplate {...props} id={id} table="clinics" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} buttons={["save", "close"]}>
        <ClinicView  />
      </ItemTemplate>
    )
}



export function ClinicView(prop) {
  // //const handleChange = (event) => {
  //   prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  // }

  const handleUncontrolledChange = (event) => {
    prop.data[event.target.name] = event.target.value;
  }

  const handleChangeClinic = () => {
    window.glob("user")["role_doctor"] = true;
    window.glob("user")["role_payment"] = true;
    window.glob("user")["role_administrator"] = true;
    window.glob("user")["clinicid"] = prop.data._id;
    Cookies.set("sa_clinicid", prop.data._id);
    Cookies.remove("sa_userid");
    window.navigate("/"); 
  }

  /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }

  console.log(prop.data.seeall)
  */

  let org_fields=["org_name", "org_inn", "org_kpp", "org_bik","org_bank", "org_bankaccount","org_address"]
  if (window.glob("user")["role_accountant"]) {
    org_fields = ['qpstartdate' , 'qpenddate','qpcount','qpport',...org_fields]
  }
  return (
    <Stack sx={{gap:2, minWidth: 400}}>
      {window.glob("user")["role_superadmin"] && 
        <Button onClick={handleChangeClinic}>Перейти в клинику</Button>
      }
      
      <Heading heading={<T>administrator clinic</T>} style={{marginTop:0}}></Heading>

      <TextField
        required
        fullWidth
        type="text"
        name="name"
        label={<T>clinic name</T>}
        onInput={handleUncontrolledChange}
        defaultValue={prop.data?.name||""}
      />

      {org_fields.map((f,i)=><TextField
        fullWidth
        key={i}
        type={f==='qpstartdate' || f=== 'qpenddate'? "date":"text"}
        name={f}
        label={<T>{f}</T>}
        onInput={handleUncontrolledChange}
        defaultValue={prop.data?.[f]||""}
      />)}
    </Stack>
  )
}