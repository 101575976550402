import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"

import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { T, t } from "../Globals.js"
import { useNavigate } from 'react-router-dom';
import Heading from '../components/Heading';


const columns = [
  { field: 'name', headerName: t("table fio"), flex: 1, minWidth:200 },
  { field: 'email', headerName: t("email"), flex: 0.5, minWidth:200 },
];



export function UsersList() {
  const navigate = useNavigate();

  
  useEffect(() => {
    window.glob("title", <T>administrator doctors</T>)
  }, [])

  const createDoctor = () => {
    navigate('/users/create');
  }

  return (

    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:90}} onClick={createDoctor} variant="extended" color="primary"><AddIcon /><T>create doctor</T></Fab>
      <Heading heading={<T>administrator doctors</T>} style={{margin: "20px auto 0 auto"}}></Heading>
      <MyDataGrid
        columns={columns}
        table="users"
        sortModel={{field:"name",sort:"asc"}}
        searchCols={[{ col: 'name' }, { col: 'email' }]}
      />
    </Box>
  );
}