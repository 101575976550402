import React,{useEffect} from 'react';
//import { useLoaderData } from "react-router-dom";

//import { DataGrid } from '@mui/x-data-grid';
import MyDataGrid from "../components/MyDataGrid";
import Img from "../components/Img";
import StatusChip from '../components/StatusChip';
import AddIcon from '@mui/icons-material/Add';

import {T,t,getImageUrl, naming} from "../Globals.js";
import { Stack, Fab, Button, Box } from '@mui/material';
import Heading from '../components/Heading';
import dayjs from 'dayjs';

let columns = [
  { field: 'idNo', headerName: t("idNo"), width: 70},
  {
    field: '',
    headerName: t("image"),
    sortable: false,
    width: 80,
    align: 'center',
    type: 'dateTime',
    renderCell: (params) => {
      const imageUrl = getImageUrl(params.row, 'image', 'small');
      return <Img src={imageUrl} alt="Image" />;
    }      
  },

  { 
    field: 'date', 
    headerName: t("date"), 
    width: 130, 
    type: 'date',
    renderCell: (params) => {
      return params.row.date ? <div style={{textWrap: "wrap"}}>{dayjs(params.row.date).format("DD.MM.YYYY")} {dayjs(params.row.date).format("HH:mm")}</div> : null;
    },
    valueGetter: (params) => {
      return params.value ? new Date(params.row.date) : null;
    },
      
  },
  { field: '_pacient', headerName: t("pacient"), width: 250, valueGetter:(p)=>naming(p.row._pacient,"pacients") },
  { field: 'status', 
    headerName: t("status"), 
    width: 130,
    align: 'center',
    renderCell: (params) => {
    return StatusChip({...params.row, type:"request"});
  } },
  
  {
    field: '_ais',
    headerName: t("ais"),
    sortable: false,
    width: 160,
    renderCell: (params) => {
      return <Stack sx={{display: 'flex', flexDirection: 'column', gap: 0.3}}>{params.row._ais?.map((r)=>(
        <StatusChip {...r} type="ai" key={r._id} />
      ))}</Stack>
    }
      //`${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  
  {
    field: '_responses',
    headerName: t("responses"),
    sortable: false,
    width: 160,
    align: 'center',
    renderCell: (params) => {
      return <Stack sx={{display: 'flex', flexDirection: 'column', gap: 0.3}}>{params.row._responses?.map((r)=>(
        <StatusChip {...r} type="so" key={r._id} />
      ))}</Stack>
    }
      //`${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  { field: '_doctor', headerName: t("doctor"), width: 250, valueGetter:(p)=>naming(p.row._doctor,"users") }
];




export  function RequestList() {

  const user = window.glob("user"); 

  if (!user.role_administrator) {
    const removeIndex = columns.findIndex(obj => obj.field === '_doctor');
    if (removeIndex) columns = columns.filter((obj, index) => removeIndex !== index);
  }

  useEffect(()=>{
    window.glob("title",<T>requests</T>)
  },[])

  return (
    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:90, display: {xs: "flex", sm: "none"}}} onClick={()=>{window.navigate("/pacients")}} variant="extended" color="primary"><AddIcon /><T>create recuest snapshot</T></Fab>
      <Heading heading={<T>requests</T>} subheading={<T>requests descr</T>} style={{margin: "30px auto 0 auto"}}></Heading>
      <Button onClick={()=>{window.navigate("/pacients")}} color="primary" sx={{maxWidth: "fit-content", margin: "0 auto", mt:1, display: {xs: "none", sm: "flex"}}}><T>create recuest snapshot</T></Button>
      <MyDataGrid
        columns={columns}
        table="requests"
        rowHeight={90}
        hideColsForMobile={['']}
        hideCols={['_ais']}
        searchCols={[{ col: 'date' }, { col: '_pacient', searchFunc: "fio" }, {col: '_doctor', searchFunc: "name"}]}
        getRowClassName={(a)=>{
          return a.row.unread && "unread"
        }}
      />
    </Box>
  );
}