import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Heading from '../components/Heading'
import Img from '../components/Img'
import { useParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { T } from '../Globals'

const fetchMarkersAndMicroImages = async (activeImage, markers) => {
  const microImagesPromises = markers.map(marker => fetch(`/api/qpmicrosbymarker/${activeImage}/${marker._id}`).then(res => res.json()));

  const microImagesResults = await Promise.all(microImagesPromises);
  const microImages = microImagesResults.filter(result => result.result === "ok").map(result => result.data).flatMap(el => el);
  return microImages;
};

const chunkArray = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
    array.slice(index * size, (index + 1) * size)
  );
};

export default function QPPatientItem2() {
  const { id } = useParams();
  const [activeImageId, setActiveImageId] = useState(null);
  const [state, setState] = useState({
    mainImages: [],
    markers: [],
    microImages: [],
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const mainImageRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    canvasRef.current.className = "absolute top-0 left-0 opacity-0";

    const fetchData = async () => {
      let imageId = activeImageId;
      let baselineData = {};

      if (imageId === null) {
        const baselineResult = await fetch(`/api/qpbaseline/${id}`).then(res => res.json());
        if (baselineResult.result !== "ok") return;

        baselineData.mainImages = baselineResult.data;
        imageId = baselineResult.data[0]._id;
        setActiveImageId(imageId);
      }

      const markersResult = await fetch(`/api/qpmarkers/${imageId}`).then(res => res.json());
      if (markersResult.result !== "ok") return;

      const microImages = await fetchMarkersAndMicroImages(imageId, markersResult.data);

      setState(prev => ({
        ...prev,
        ...baselineData,
        markers: markersResult.data,
        microImages,
      }));
    };

    fetchData()
  }, [id, activeImageId]);

  const size = isMobile ? 2 : 6;
  const prepareBaseline = chunkArray(state.mainImages, size);
  const prepareMicro = chunkArray(state.microImages, size);

  const drawArrow = (x1, y1, x2, y2, markerId) => {
    const ctx = canvasRef.current.getContext('2d');
    const headLength = 15;

    ctx.beginPath();
    ctx.moveTo(x2, y2);
    ctx.lineTo(x1, y1);
    ctx.lineWidth = 1.5;
    ctx.strokeStyle = 'aqua';
    ctx.stroke();

    const angle = Math.atan2(y1 - y2, x1 - x2); //угол для головы стрелки

    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x1 - headLength * Math.cos(angle - Math.PI / 6), y1 - headLength * Math.sin(angle - Math.PI / 6));
    ctx.moveTo(x1, y1);
    ctx.lineTo(x1 - headLength * Math.cos(angle + Math.PI / 6), y1 - headLength * Math.sin(angle + Math.PI / 6));
    ctx.stroke();

    ctx.fillStyle = 'aqua';
    ctx.font = '12px Arial';
    ctx.fillText(`[${markerId}]`, x2 + 5, y2 - 5);
  };

  const updateCanvas = () => {
    const selectedImage = state.mainImages.find(img => img._id === activeImageId);

    if (mainImageRef.current && canvasRef.current && selectedImage) {
      canvasRef.current.className = "absolute top-0 left-0 opacity-1";

      const ctx = canvasRef.current.getContext('2d');
      const imageWidth = mainImageRef.current.clientWidth;
      const imageHeight = mainImageRef.current.clientHeight;

      canvasRef.current.width = imageWidth;
      canvasRef.current.height = imageHeight;

      const scaleX = imageWidth / selectedImage.ImageWidth;
      const scaleY = imageHeight / selectedImage.ImageHeight;

      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      state.markers.forEach(marker => {
        const scaledX1 = marker.MarkPoint1X * scaleX;
        const scaledY1 = marker.MarkPoint1Y * scaleY;
        const scaledX2 = marker.MarkPoint2X * scaleX;
        const scaledY2 = marker.MarkPoint2Y * scaleY;
          
        drawArrow(scaledX1, scaledY1, scaledX2, scaledY2, marker.MarkText);
      });  
    }
  };

  const handleImageLoad = () => {
    updateCanvas();
  };

  return (
    <Box sx={{ maxWidth: window.maxWidth, width: "100%", margin: "20px auto", px: 2, display: "flex", flexDirection: "column" }}>
      <Heading heading={<T>QP picture patient</T>} style={{ margin: 0 }} />
      
      <div style={{  width: "100%", margin: "auto 0 auto", height: isMobile ? 350 : 500}}>
        <div className='relative w-fit m-auto h-full'>
          <Img id="mainImage" elRef={mainImageRef} style={{ width: "100%", height: "100%", objectFit: "contain" }} src={activeImageId ? "/api/qpimage/"+activeImageId : ""} onLoad={handleImageLoad} />
          <canvas ref={canvasRef} className='absolute top-0 left-0 opacity-0' id="canvas"></canvas>
        </div>
      </div>      

      {prepareBaseline.length > 0 &&
        <Box sx={{ mt: 2,  width: "100%", mx: "auto", maxWidth: isMobile ? "300px" : "100%"}}>
          <Typography variant='body2' color='primary' sx={{mb:1}} textAlign={"left"}><T>QP overview</T></Typography>
          <Slider imagesData={state.mainImages} setActiveImageId={setActiveImageId} isMobile={isMobile}/>
        </Box>
      }
      {prepareMicro.length > 0 &&
        <Box sx={{ mt: 2,  width: "100%", mx: "auto", maxWidth: isMobile ? "300px" : "100%"}}>
          <Typography variant='body2' color='primary' sx={{mb:1}} textAlign={"left"}>QP microshot</Typography>
          <Slider imagesData={state.microImages} setActiveImageId={setActiveImageId} markers={state.markers} isMobile={isMobile}/>
        </Box>
      }
    </Box>
  )
}

function Slider({ imagesData, setActiveImageId, markers, isMobile }) {
  return (
    <Splide options={{rewind: true, perPage: isMobile ? 5 : 8, perMove: 1, pagination: false, }}>
      {
        imagesData?.map((el, i) => {
          const m =  markers?.find(m => m._id === el.MedPoint);
          return (
            <SplideSlide key={i} className="flex justify-center relative">
              <img onClick={() => setActiveImageId(el?._id)} className={`${isMobile ? "w-8" : "w-20"} h-fit m-auto cursor-pointer`} src={`${'/api/qpicon/' + el?._id}`} alt={"Image "+i}/>
              {m && <Typography variant='body2' sx={{ textAlign:"center",  }} className='absolute p-1 bg-black bottom-0 pointer-events-none' color={"secondary"}>{m?.MarkText}</Typography>}
            </SplideSlide>
          );
        })
      }
    </Splide>
  )
}