import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { axios, T, getImageUrl, getConvertDate, Alert} from "../Globals.js"
import { Stack,TextField,  MenuItem , Paper, Box, Button, Typography} from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import Img from "../components/Img";
//import {ShareRounded, SchoolRounded} from '@mui/icons-material';
import StatusChip from '../components/StatusChip';

import {RequestItem} from "../pages/RequestItem";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import scss from "./PacientsItem.module.scss"; 
//import Heading from '../components/Heading.js';
import Sex from '../components/Sex.js';
import SuperAdminPanel from '../components/SuperAdminPanel.js';
import { CardsList } from '../components/CardsList.js';

var applyHndlr = null;
var onApplyHndlr = null;
export function PacientsItem(props) {
  const params = useParams();
  let [errorCount, setErrorCount] = useState(0);

  let [data, setData] = useState({});
  const saveHandler = async () => { 
    return new Promise((resolve,reject)=>{
      if (errorCount===0) {
        resolve()
      } else {
        Alert(<T>required error alert</T>).then(()=>{
          reject()
        })
      }
    })
  }
  const cancelHandler = async () => { }
  const deleteHandler = async () => { }
  
  const getButtons = () => data?.id === "create" ? ["save", "cancel"]: ["save", "cancel", "delete"];

  let errors = {};
  const validator=()=>{
    //  console.log(data)
    errors = {}
    let count = 0
    let required = ["surname","name","birth","sex","phototype","znok"] 
    required.forEach((e)=>{
      //проверка на заполненность 
      if (data && !data[e]) {count++; errors[e] = <T>required</T>;}  
    })
    
    if (errorCount !== count) setErrorCount(count);
  }
  validator()


  return (
    <>
      <ItemTemplate {...props} errors={errors} validator={validator} title={props.data?.id==="create"?<T>create new pacient</T>:<T>pacient item</T>} table="pacients" data={data} setData={setData} buttons={getButtons()} onSave={saveHandler} onApply={saveHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
        <PacientsItemView  />
      </ItemTemplate>
      {props.template!=='popup' && <PacientsItemGallery  {...props} data={data} id={params.id} /> }
    </>
  )
}



export function PacientsItemView(prop) {

  onApplyHndlr = prop.onApply;
  applyHndlr = prop.apply;
  
    /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  */

  const handleUncontrolledChange = (event) => {
    prop.data[event.target.name] = event.target.value;
    prop.validator()
  }
  const handleChange = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.value });
  }

  return (
    <Stack sx={{gap:2}} className={scss.pacient_listitem}>

      {/* Ходят легенды, что если удалить этот элемент начнется апокалипсис. Поэтому не удаляй его */}
      <SuperAdminPanel {...prop} handleChange={handleChange} />

      <TextField
        fullWidth
        type="text"
        name="surname"
        label={<T>surname</T>}
        onChange={handleUncontrolledChange}
        defaultValue={prop.data?.surname || ""}
        error={!!prop.errors.surname}
        helperText={prop.errors.surname || ""}
      />
      <TextField
        
        fullWidth
        type="text"
        name="name"
        label={<T>pacient name</T>}
        onChange={handleUncontrolledChange}
        defaultValue={prop.data?.name || ""}
        error={!!prop.errors.name}
        helperText={prop.errors.name || ""}
      />
      <TextField
        
        fullWidth
        type="text"
        name="patronymic"
        label={<T>patronymic</T>}
        onChange={handleUncontrolledChange}
        defaultValue={prop.data?.patronymic || ""}
      />
      <TextField
        fullWidth
        type="text"
        name="cardid"
        label={<T>pacient card</T>}
        onChange={handleUncontrolledChange}
        defaultValue={prop.data?.cardid || ""}

      />

      <Stack sx={{flexDirection: "row", gap: 2, flexWrap:"nowrap"}} className={scss.birtdate_container}>
        <DatePicker
          label={<T>birth</T>}
          name="birth"
          value={ prop.data?.birth  ? dayjs(prop.data.birth) : "" }
          format="DD-MM-YYYY"
          inputFormat="YYYY-MM-DD"
          onChange={(date) => {prop.setData({ ...prop.data, "birth": dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') })}}
          sx={{flex:3}}
          error={!!prop.errors.birth}
          helperText={prop.errors.birth || ""}
        />
        <TextField sx={{flex:1}} label={<T>age</T>} aria-readonly value={calculateAge(prop.data?.birth)} />
      </Stack>

      <Sex handleChange={handleChange} data={prop.data} />

      {/* <TextField
        select
        fullWidth
        label={<T>sex</T>}
        value={prop.data?.sex || ""}
        name="sex"
        onChange={handleChange}

      >
        <MenuItem value="">none</MenuItem>
        <MenuItem value="male"><T>male</T></MenuItem>
        <MenuItem value="female"><T>female</T></MenuItem>
      </TextField> */}

      <TextField
        select
        fullWidth
        label={<T>phototype</T>}
        value={prop.data?.phototype || ""}
        name="phototype"
        onChange={handleChange}
        error={!!prop.errors.phototype}
        helperText={prop.errors.phototype || ""}
      >
        <MenuItem value=""><T>unknown</T></MenuItem>
        <MenuItem value="1"><T>phototype 1</T></MenuItem>
        <MenuItem value="2"><T>phototype 2</T></MenuItem>
        <MenuItem value="3"><T>phototype 3</T></MenuItem>
        <MenuItem value="4"><T>phototype 4</T></MenuItem>
        <MenuItem value="5"><T>phototype 5</T></MenuItem>
        <MenuItem value="6"><T>phototype 6</T></MenuItem>
      </TextField>

      <TextField
        sx={{mb: 2}}
        fullWidth
        label={<T>family anamnez</T>}
        defaultValue={prop.data?.znok || ""}
        name="znok"
        onChange={handleUncontrolledChange}
        error={!!prop.errors.znok}
        helperText={prop.errors.znok || ""}
      ></TextField>
    </Stack>
  )
}

export function calculateAge(birthDate) {
  if(!birthDate) return ""
  birthDate = new Date(birthDate);
  if (birthDate !== "Invalid Date") {
    // prop.setData({ ...prop.data, birthday: "2000-12-07" })
    birthDate = birthDate.getTime();
    const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365;
    const age = Math.floor((Date.now() - birthDate) / millisecondsPerYear);

    if (age < 1) {
      const millisecondsPerMonth = 1000 * 60 * 60 * 24 * 30;
      const months = Math.floor((Date.now() - birthDate) / millisecondsPerMonth);

      if (months < 1) {
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const days = Math.floor((Date.now() - birthDate) / millisecondsPerDay);

        return `${days} ${getCorrectNounForm(days, ["день", "дня", "дней"])}`;
      }

      return `${months} ${getCorrectNounForm(months, ["месяц", "месяца", "месяцев"])}`;
    }
  
    return `${age} ${getCorrectNounForm(age, ["год", "года", "лет"])}`;
  }

  function getCorrectNounForm(number, forms) {
    if (isNaN(number)) return ""
    const remainder100 = number % 100;
    const remainder10 = number % 10;
    if (remainder100 >= 11 && remainder100 <= 20) {
      return forms[2];
    } else if (remainder10 === 1) {
      return forms[0];
    } else if (remainder10 >= 2 && remainder10 <= 4) {
      return forms[1];
    } else {
      return forms[2];
    }
  }
  return ""
}

export function PacientsItemGallery(prop) {
  let [data, setData] = useState([]);
  const [active, setActive] = useState("");
  const [open, setOpen] = useState(false);
  const [random, setRandom] = useState(0);
  
  const updateCountHandler = ()=>{
    setRandom(Math.random())
  }
  useEffect (()=>{
    if (!prop.data?._id) return;
    (async ()=>{
      const filter = {"items":[{"field":"pacientid","operator":"equals","value":prop.data._id}],"logicOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"}
      const resp = await axios.get("/api/requests?filter="+JSON.stringify(filter));
      setData(resp.data.data.map((a)=>{return {...a, id:a._id}}));
    })()
  },[prop.data?._id, random])

  const handleCardСlick = (e, cardData) => {
    setActive(cardData._id);
    setOpen(true);
  }


  //Эта штука нужна, иначе не привязывается пациент при запросе. Почему - хз, но так работает..... Конкретно - не проваливается defaults pacientid
  //if (!prop.id || prop.id==='create' || !prop.data?._id) return <></>;
  //console.log(prop.data._id)

  //<Help sx={{margin:"0 auto 24px auto", maxWidth:window.maxWidth}}><T>pacient gallery help</T></Help>
  return (
    <>
      <Button  color="secondary" sx={{display: "block", mx: "auto", mt:-2, width:"300px"}} onClick={async ()=>{
        //await prop.apply()
        try{
          await onApplyHndlr()
          await applyHndlr()
          setActive("create")
          setOpen(true)
        } catch(e){}
        
      }}><T>create recuest snapshot</T></Button>

      <Box sx={{backgroundColor:"#dedfe1", px:2, py: 4 }}>
        

        <RequestItem template="popup" id={active} openState={[open, setOpen]} defaults={{pacientid:prop.data._id, _pacient:prop.data}} onCreate={updateCountHandler} onDelete={updateCountHandler} />

        <CardsList 
          template={"pacientsRequests"}
          cards={data}
          handleСlick={handleCardСlick} 
        />
        
      </Box>
    </>
  )
}

// function GalleryImage({data, setActive, setOpen}) {

//   if (data.ai) return;

//   let color = (data.status === "sent") ? "green" : "grey";  
//   return (
//     <Paper className={data.unread ? "unread" : null} sx={{minWidth: "fit-content", p: 2, cursor: "pointer"}} onClick={()=>{setActive(data._id); setOpen(true)}}>
//       <Box mb={2} style={{color: color, width: "100%", textAlign: "center", borderRadius: "8px 8px 0 0", fontSize:"0.7rem"}}>
//         <Typography><T>{"status "+data.status}</T></Typography>
//         <Typography variant='body2' sx={{marginTop: "4px"}}>{getConvertDate(data.date)}</Typography>
//       </Box>
      
//       <Img alt="im1" src={getImageUrl(data,"image","small")} style={{display: "block", width:"165px", height:"165px", margin: "0 auto", objectFit:"cover"}}/>
      
//       <Stack gap={1.5} mt={2} px="2px">
//         <Box sx={{width: "100%", mx: "auto", display: "flex", flexDirection: "column", gap: 0.5}}>
//           {(data._responses && Object.keys(data._responses).length !== 0) && (
//             data._responses?.map((r)=><StatusChip {...r} key={r._id} type="so" sx={{width: "100%", mx: "auto", "& .MuiChip-label": {pl: 0}}} />)
//           )}  
//         </Box>
//         <Box sx={{width: "100%", mx: "auto", display: "flex", flexDirection: "column", gap: 0.5}}>
//           {(data._ais && Object.keys(data._ais).length !== 0) && (
//             data._ais?.map((r)=><StatusChip {...r} key={r._id} type="ai" sx={{width: "100%", mx: "auto", "& .MuiChip-label": {pl: 0}}} />)
//           )}
//         </Box>
//       </Stack>
//     </Paper>
//   );
  
// }


